<h1 class="!tw-text-alt2">You've chosen Bitwarden for Families</h1>
<div class="tw-pt-24">
  <h2>
    Trusted by millions of individuals, teams, and organizations worldwide for secure password
    storage and sharing.
  </h2>
</div>
<div class="tw-text-3xl tw-text-main tw-mt-12">
  <p class="tw-mt-2.5 tw-mb-20">Collaborate and share securely</p>
  <p class="tw-mt-2.5 tw-mb-20">Deploy and manage quickly and easily</p>
  <p class="tw-mt-2.5 tw-mb-20">Access anywhere on any device</p>
  <p class="tw-mt-2.5 tw-mb-20">Create your account to get started</p>
</div>
